import { createApp } from 'vue';
import { createPinia } from 'pinia'
import App from './App.vue';
import router from './router';
import { auth } from './firebaseConfig';
import { onAuthStateChanged } from "firebase/auth";

let app;
const pinia = createPinia();

onAuthStateChanged(auth, (user) => {
  if (!app) {
    
    // Crea l'istanza dell'app
    app = createApp(App);

    // Usa il router e monta l'app solo una volta
    app.use(router).mount('#app');
    app.use(pinia);
  }

  // Gestisci il redirect in base allo stato dell'autenticazione
  if (user) {
    router.push('/dashboard');
  } else {
    router.push('/login');
  }
});
