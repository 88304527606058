<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  mounted() {
    document.addEventListener('contextmenu', this.disableRightClick);
    document.addEventListener('wheel', this.disableZoom, { passive: false });
    document.addEventListener('keydown', this.disableKeyZoom);
    window.addEventListener('resize', this.preventZoom);
  },
  beforeUnmount() {
    document.removeEventListener('contextmenu', this.disableRightClick);
    document.removeEventListener('wheel', this.disableZoom);
    document.removeEventListener('keydown', this.disableKeyZoom);
    window.removeEventListener('resize', this.preventZoom);
  },
  methods: {
    disableRightClick(event) {
      event.preventDefault();
    },
    disableZoom(event) {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
      }
    },
    disableKeyZoom(event) {
      if (event.ctrlKey && (event.key === '+' || event.key === '-' || event.key === '0') ||
          event.metaKey && (event.key === '+' || event.key === '-' || event.key === '0')) {
        event.preventDefault();
      }
      if (event.key === 'Meta' || event.key === 'Control') {
        window.addEventListener('gesturestart', this.preventZoom);
        window.addEventListener('gesturechange', this.preventZoom);
        window.addEventListener('gestureend', this.preventZoom);
      }
    },
    preventZoom(event) {
      if (event.scale && event.scale !== 1) {
        event.preventDefault();
      }
    }
  }
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 96vh;
  display: flex;
  flex-direction: row;
}

.ag-grid-vue {
  width: 100% !important; 
  height: 100% !important; 
}

.ag-header-cell-label {
  justify-content: center !important;
  color: #7A929E;
  font-size: 14.5px; 
}

.ag-icon-filter {
  color: #7A929E;
}

.ag-cell {
  font-family: 'Arial';
  font-size: 14px; 
  color: #787878;
}

.ag-paging-row-summary-panel {
  color: #7A929E; 
}

.ag-paging-panel {
  color: #7A929E; /* Cambia il colore del testo a rosso */
}

.ag-paging-button {
  color: #7A929E; /* Cambia il colore del testo dei pulsanti di paginazione a blu */
}
</style>
