import { initializeApp } from 'firebase/app'; // Import initializeApp directly
import { getFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDXeyRUFVUGqhGz-HKg89znV9cvMkUUMAM",
  authDomain: "fluttere-distribuzione.firebaseapp.com",
  projectId: "fluttere-distribuzione",
  storageBucket: "fluttere-distribuzione.appspot.com",
  messagingSenderId: "195729499651",
  appId: "1:195729499651:web:cb2b353eed6bc888f85036"
};

const app = initializeApp(firebaseConfig); // Initialize app 
const db = getFirestore(app); // Get Firestore instance

export { db };