import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/LoginPage.vue'
import PageProgetti from '../components/PageProgetti.vue'
import Dashboard from '../components/DashboardPage.vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const routes = [
    { path: '/', redirect: '/login' },
    { path: '/login', name: 'login', component: Login },
    { path: '/dashboard', name: 'dashboard', component: Dashboard, meta: { requiresAuth: true } },
    { path: '/commesse', name: 'commesse', component: PageProgetti, meta: { requiresAuth: true } },
    // Aggiungi altre rotte qui
  ];
  
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
  // Navigation Guard
  router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const auth = getAuth();
    const isAuthenticated = auth.currentUser;
  
    if (requiresAuth && !isAuthenticated) {
      next('/login');
    } else {
      next();
    }
  });
  
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      if (router.currentRoute.value.path === '/login') {
        router.push('/dashboard');
      }
    } else {
      if (router.currentRoute.value.meta.requiresAuth) {
        router.push('/login');
      }
    }
  });
  
  export default router;