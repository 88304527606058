<template>
  <div class="sidebar" :class="{ 'sidebar-closed': !isSidebarOpen }">
    <button class="toggle-button" @click="toggleSidebar">
      <i class="fas fa-bars"></i>
    </button>
    <button v-if="isSidebarOpen" class="lock-button" @click="toggleSidebarLock">
      <i :class="isSidebarLocked ? 'fas fa-lock' : 'fas fa-lock-open'"></i>
    </button>
    <div class="sidebar-content">
      <div class="spacer"></div>
      <div class="spacer"></div>
      <div class="spacer"></div>
      <div class="spacer"></div>
      <div class="spacer"></div>
      <!-- Logo SVG -->
      <div class="logo-container" v-if="isSidebarOpen">
        <img :src="logo" alt="App Logo" class="app-logo" />
      </div>
      <!-- Campo di ricerca -->
      <div v-if="isSidebarOpen" class="search-container">
        <button @click="resetSearch" class="reset-button">
          <i class="fas fa-times"></i>
        </button>
        <input type="text" v-model="searchQuery" placeholder="Cerca..." class="search-input" />
      </div>
      <div class="spacer"></div>
      <!-- Pulsanti della sidebar -->
      <div class="sidebar-button" @click="onButtonClick('home')" :class="{ 'sidebar-button-closed': !isSidebarOpen }">
        <i class="fas fa-chart-bar"></i>
        <span v-if="isSidebarOpen">Dashboard</span>
      </div>
      <!-- Aggiungi i restanti pulsanti della sidebar qui -->
      <div class="sidebar-button" @click="onButtonClick('user')" :class="{ 'sidebar-button-closed': !isSidebarOpen }">
        <i class="fas fa-inbox"></i>
        <span v-if="isSidebarOpen">Inbox</span>
      </div>
      <div class="sidebar-button" @click="onButtonClick('commesse')" :class="{ 'sidebar-button-closed': !isSidebarOpen }">
        <i class="fas fa-outdent"></i>
        <span v-if="isSidebarOpen">Workspaces</span>
        <button v-if="isSidebarOpen" class="add-item-button" @click="addItem">
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <div class="scrollable-content">
<div
  v-for="(item, index) in filteredWorkspaceItems"
  :key="item.id"
  class="sidebar-button workspace-item"
  :class="{ 'sidebar-button-closed': !isSidebarOpen, 'editable': item.editing, 'workspace-item-closed': !isSidebarOpen }"
  @contextmenu.prevent="openContextMenu($event, item)"
  @click.stop="onButtonClick('workspace', item.id)" 
  :ref="'workspaceItem' + index"
>
  <i class="far fa-folder"></i>
  <span v-if="isSidebarOpen">
    <input
      v-if="item.editing"
      type="text"
      v-model="item.nome"
      @blur="stopEditing(item)"
      @keyup.enter="stopEditing(item)"
      @click.stop
      :ref="'workspaceInput' + index"
    />
    <span v-else>{{ item.nome }}</span>
  </span>
</div>
<!-- Menu contestuale -->
<div
  v-if="contextMenuVisible"
  :style="{ top: `${contextMenuY}px`, left: `${contextMenuX}px` }"
  class="context-menu"
>
  <ul>
    <li @click="startEditing(selectedItem)">Edita</li>
    <!-- Altre opzioni, se necessarie -->
  </ul>
</div>

      </div>
      <div class="spacer"></div>
      <!-- Il mio profilo -->
      <div class="fixed-bottom-container">
        <div class="sidebar-button" @click="myaccount" :class="{ 'sidebar-button-closed': !isSidebarOpen }">
          <i class="fas fa-user"></i>
          <span v-if="isSidebarOpen">My account</span>
        </div>
        <!-- Logout button -->
        <div class="sidebar-button" @click="logout" :class="{ 'sidebar-button-closed': !isSidebarOpen }">
          <i class="fas fa-sign-out-alt"></i>
          <span v-if="isSidebarOpen">Logout</span>
        </div>
      </div>
    </div>
  </div>
  <div class="main-content" :style="mainContentStyle">
    <component :is="currentComponent" />
    <div v-if="currentComponent === 'FolderView'" class="grid-container">
      <DraggableGrid @open-form="openForm" />
    </div>
  </div>
</template>

<script>
import { getAuth, signOut } from 'firebase/auth';
import { db } from '@/firebaseConfig2';
import { collection, query, where, getDocs, setDoc, doc, updateDoc, onSnapshot } from 'firebase/firestore';
import PageProgetti from './PageProgetti.vue';
import PageCommesse from './PageCommesse.vue';
import _ from 'lodash';
import eventBus from './eventBus.js';

export default {
  components: {
    PageProgetti,
    PageCommesse,
  },
  data() {
  return {
    isSidebarOpen: true,
    isSidebarLocked: false,
    logo: require('@/assets/logo.svg'),
    currentComponent: null,
    showForm: false,
    currentFolder: '',
    workspaceItems: [],
    newItemId: null,
    searchQuery: '',
    userData: null,
    email: localStorage.getItem('email'),
    contextMenuVisible: false,
    contextMenuX: 0,
    contextMenuY: 0,
    selectedItem: null,  // Memorizza l'elemento selezionato
  };
  },
  computed: {
    mainContentStyle() {
      return {
        width: this.isSidebarOpen ? 'calc(100vw - 240px)' : 'calc(100vw - 90px)',
        overflow: 'auto'
      };
    },
    filteredWorkspaceItems() {
      // Filtra i workspaces in base alla query di ricerca
      return this.workspaceItems.filter(item => 
        item.nome && item.nome.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    toggleSidebarLock() {
      this.isSidebarLocked = !this.isSidebarLocked;
    },
    openContextMenu(event, item) {
      this.contextMenuX = event.clientX;
      this.contextMenuY = event.clientY;
      this.contextMenuVisible = true;
      this.selectedItem = item;  // Imposta l'elemento selezionato
    },
    closeContextMenu() {
      this.contextMenuVisible = false;
    },
    startEditing(item) {
      this.closeContextMenu();  // Chiudi il menu contestuale prima di modificare
      item.editing = true;
      this.$nextTick(() => {
        const input = this.$refs[`workspaceInput${this.workspaceItems.indexOf(item)}`][0];
        if (input) input.focus();
      });
    },
    handleClickOutside(event) {
      // Chiudi il menu contestuale se si clicca fuori
      if (!this.$el.contains(event.target)) {
        this.closeContextMenu();
      }
    },

onButtonClick: _.debounce(function(button, id = null) {
    //console.error('Button clicked:', button, 'ID:', id);

    if (button === 'progetti') {
      this.currentComponent = 'PageProgetti';
    } else if (button === 'commesse' || button === 'workspace') {
      this.currentComponent = 'PageCommesse';
      
      if (id !== null) {
        localStorage.setItem('cliccato', id); // Salva l'ID del workspace cliccato
        eventBus.emit('cliccatoChanged', id);
      } else if (button === 'commesse') {
        localStorage.setItem('cliccato', 'workspace');
        eventBus.emit('cliccatoChanged', 'workspace');
      }
      console.log('Cliccato:', localStorage.getItem('cliccato'));

    } else {
      this.currentComponent = null;
    }
  }, 200), // Debounce con un ritardo di 200ms
  
    getCurrentDateLocale() {
      const today = new Date();
      return today.toLocaleDateString();  // Esempio: "7/21/2024"
    },


async addItem(event) {
  event.stopPropagation();

  // Genera un nuovo ID
  const id = await this.generateTimestampId();

  // Controlla l'ID utente
  const userId = localStorage.getItem('userId');
  if (!userId || typeof userId !== 'string') {
    console.error('Errore: User ID non trovato o non è una stringa valida.', userId);
    return;
  }

  // Genera il nome della collezione
  const collectionName = `wk-${userId}`;
  
  // Logga i dettagli
  console.log('Generated ID:', id);
  console.log('User ID:', userId);
  console.log('Collection Name:', collectionName);

  // Verifica che `id` sia una stringa
  if (typeof id !== 'string') {
    console.error('Errore: ID generato non è una stringa valida.', id);
    return;
  }

  // Crea un nuovo elemento
  const newItem = {
    id: id,
    nome: 'Nuovo workspace',
    dataCreazione: this.getCurrentDateLocale(),
    action: '',
  };

  try {
    
    // Aggiungi il documento a Firestore
    await setDoc(doc(db, collectionName, id), newItem);
    const id2 = await this.generateTimestampId();
    await setDoc(doc(db, id, id2), {});
    console.log('Documento aggiunto con successo:', newItem);

    
    // Aggiorna l'interfaccia utente
    this.newItemId = id; // Memorizza l'ID dell'elemento appena creato
  } catch (e) {
    console.error('Errore durante l\'aggiunta del documento:', e);
  }
},

    startEditing(item) {
      item.editing = true;
      item.isNew = false;  // Ensure the 'new' class is removed when editing starts
    },

    
    async stopEditing(item) {
      const userId = localStorage.getItem('userId');
      const collectionName = `wk-${userId}`;

      item.editing = false;
      try {
        await updateDoc(doc(db, collectionName, item.id), { nome: item.nome });
      } catch (e) {
        console.error('Error updating document: ', e);
      }
    },
    openForm(folderName) {
      this.currentFolder = folderName;
      this.showForm = true;
    },
    closeForm() {
      this.showForm = false;
      this.currentFolder = '';
    },
    async logout() {
      const auth = getAuth();
      try {
        await signOut(auth);
        this.$router.push('/login');
      } catch (error) {
        console.error('Error logging out:', error);
      }
    },
    handleResize() {
      if (!this.isSidebarLocked) {
        this.isSidebarOpen = window.innerWidth >= 1000;
      }
    },
    loadWorkspaceItems() {
      const userId = localStorage.getItem('userId');
      const collectionName = `wk-${userId}`;

      const workspaceCollection = collection(db, collectionName);
      onSnapshot(workspaceCollection, (snapshot) => {
        this.workspaceItems = [];
        snapshot.forEach((doc) => {
          this.workspaceItems.push({ id: doc.id, ...doc.data(), editing: false });
        });

        // Verifica se esiste un nuovo elemento e attiva l'editing
        if (this.newItemId) {
          const newItemIndex = this.workspaceItems.findIndex(item => item.id === this.newItemId);
          if (newItemIndex !== -1) {
            this.startEditing(this.workspaceItems[newItemIndex]);
            this.$nextTick(() => {
              const input = this.$refs[`workspaceInput${newItemIndex}`][0];
              if (input) {
                input.focus();
              }
            });
          }
          this.newItemId = null; // Reset della variabile newItemId
        }
      }, (error) => {
        console.error('Error fetching workspace items: ', error);
      });
    },
    resetSearch() {
      this.searchQuery = '';
    },
    async fetchAndLogUserData() {
      // Fetch user data
      await this.fetchUserData();

      if (!this.userData || !this.userData.id) {
        console.error('User data or ID not found.');
        return;
      }

      // Extract the ID from userData.id
      const userId = this.userData.id.split('us-')[1]; // This gives you 'ID20240815175109556'
      localStorage.setItem('userId', userId);
      const collectionName = `wk-${userId}`; // Collection name

      try {
        // Reference to the collection
        const collectionRef = collection(db, collectionName);

        // Check if the collection exists by querying documents in it
        const snapshot = await getDocs(collectionRef);
        const id = await this.generateTimestampId();
        if (snapshot.empty) {
          // The collection doesn't exist, so create it
          console.log(`Creating collection: ${collectionName}`);
          
          await setDoc(doc(collectionRef, id), {
            id: id,
            nome: 'Nuovo workspace',
            dataCreazione: this.getCurrentDateLocale(),
            action: '',
          }
          );
         const id2 = await this.generateTimestampId();
         await setDoc(doc(db, id, id2), {});
        await setDoc(doc(db, `wk-${userId}`, `wk-${userId}`), {
          colonne: {
            action: {
              order: 4,
              size: 200,
              visible: true,
            },
            dataCreazione: {
              order: 3,
              size: 200,
              visible: true,
            },
            id: {
              order: 1,
              size: 200,
              visible: true,
            },
            nome: {
              order: 2,
              size: 200,
              visible: true,
            }

          }
         });



        } else {
          console.log(`The collection ${collectionName} already exists.`);
        }
      } catch (error) {
        console.error('Error accessing the Firestore collection:', error);
      }
    },


    
    async generateTimestampId() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hour = String(now.getHours()).padStart(2, '0');
      const minute = String(now.getMinutes()).padStart(2, '0');
      const second = String(now.getSeconds()).padStart(2, '0');
      const millisecond = String(now.getMilliseconds()).padStart(3, '0');
      return `${year}${month}${day}${hour}${minute}${second}${millisecond}`;
    },

    async fetchUserData() {
        const mail = localStorage.getItem('email');
        try {
            const usersCollection = collection(db, 'users');
            const q = query(usersCollection, where('email', '==', mail)); // Usa 'mail' invece di 'this.mail'
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                  this.userData = doc.data(); // Memorizza i dati dell'utente
                  //console.log(this.userData); 
                });
            } else {
                console.error('No user found with the provided email.');
            }
        } catch (error) {
            console.error('Error fetching user data: ', error);
            this.error = 'Error fetching user data';
        }
    },

  },
  mounted() {
    this.fetchAndLogUserData();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    this.loadWorkspaceItems();
    window.addEventListener('click', this.handleClickOutside);
  },
  
  beforeDestroy() {
    // Remove window event listeners
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('click', this.handleClickOutside);

    // Unsubscribe from Firebase onSnapshot listener
    if (this.unsubscribe) {
      this.unsubscribe(); // Assuming `this.unsubscribe` is set when you set up the Firebase listener
    }

    // Event Bus Cleanup (if you had registered any event listeners)
    eventBus.off('cliccatoChanged', this.handleCliccatoChange); // Example, adjust based on your event listeners

    // Other cleanup code, if necessary...
  },

};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 96vh;
  display: flex;
  flex-direction: row;
  user-select: none;
}

.sidebar {
  width: 220px;
  transition: width 0.3s;
  background-color: #f8f9fa;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
}

.sidebar-closed {
  width: 50px;
}


.toggle-button {
  position: absolute;
  top: 4px;
  right: 17px;
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0);
  color: white;
  border: none;
  cursor: pointer;
}

.toggle-button .fas {
  color: #7A929E;
  margin-top: -10px;
  font-size: 15px;
  font-weight: bold;
}

.lock-button {
  position: absolute;
  top: 4px;
  right: 40px;
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0);
  color: white;
  border: none;
  cursor: pointer;
}

.lock-button .fas {
  color: #7A929E;
  margin-top: -10px;
  font-size: 12px;
  font-weight: bold;
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.app-logo {
  max-width: 170px;
  height: auto;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: space-between;
}

.scrollable-content {
  overflow-y: auto;
  flex-grow: 1;
  height: calc(100vh - 200px); /* Adjust this height based on the actual height of fixed elements like the logo, search bar, and footer */
  padding-bottom: 20px; /* Space for fixed buttons at the bottom */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.scrollable-content::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.sidebar-button {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align icons and text to the left */
  padding: 10px 15px;
  margin-bottom: 7px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: left;
  position: relative;
}

.workspace-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar-button-closed {
  justify-content: center; /* Center icons when sidebar is closed */
}

.sidebar-button i {
  margin-right: 10px;
  font-size: 16px;
  color: #7A929E;
}

.sidebar-button-closed i {
  margin-right: 0; /* Remove margin when sidebar is closed */
}

.sidebar-button span {
  font-size: 14px;
  color: #7A929E;
  font-weight: bold;
}

.sidebar-button:hover {
  background-color: rgba(0,0,0,0.1);
}

.sidebar-button-closed span {
  display: none; /* Hide text when sidebar is closed */
}


.sidebar-button .add-item-button {
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  color: #ff0000 !important; /* Modifica il colore per verificare */
  font-size: 12px !important; /* Modifica la dimensione del carattere */
  margin-left: 45px !important; /* Modifica il margine sinistro */

}

.sidebar-button.workspace-item {
  font-size: 12px;
  color: #7A929E;
  margin-left: 10px;
  font-weight: 500;
  margin-bottom: -8px;
}

.workspace-item.workspace-item-added {
  font-weight: 500;
}

.workspace-item.new-item {
  background-color: #e0f7fa;
  border-left: 4px solid #00796b;
  font-weight: 500;
}

.workspace-item input {
  width: 100%;
  border: none;
  background: none;
  color: #7A929E;
  font-size: 13px;
}

.workspace-item.editable input {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
}

.main-content {
  flex-grow: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.grid-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.folder-item {
  display: inline-block;
  margin: 10px;
  cursor: pointer;
}

.spacer {
  height: 20px;
}

.fixed-bottom-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 10px;
  width: calc(100% - 30px);
  align-items: flex-start; /* Aggiunto per allineare le icone */
}

.fixed-bottom {
  background-color: white;
  margin-bottom: 0px;
  height: 30px;
}


.fixed-bottom-container .sidebar-button {
  width: 100%; /* Imposta la larghezza al 100% */
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align icons and text to the left */
  padding: 10px 15px;
  margin-bottom: 7px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: left;
}

.fixed-bottom-container .sidebar-button i {
  margin-right: 10px;
  font-size: 16px;
  color: #7A929E;
}

.fixed-bottom-container .sidebar-button span {
  font-size: 14px;
  color: #7A929E;
  font-weight: bold;
}

.fixed-bottom-container .sidebar-button:hover {
  background-color: rgba(0,0,0,0.1);
}

.search-container {
  display: flex;
  align-items: center;
  margin: 0px 10px 0px 0px !important; /* Add margin to the right of the search field */
}

.search-input {
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
}

.reset-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #7A929E;
  font-size: 11px;
  margin-left: 0px;
}

/* Aggiungi queste regole per nascondere i workspaces quando la sidebar è chiusa e per allineare correttamente le icone */
.scrollable-content .workspace-item {
  display: flex;
}

.scrollable-content .workspace-item-closed {
  display: none;
}

.sidebar-closed .workspace-item {
  display: none; /* Nasconde i workspaces quando la sidebar è chiusa */
}

.context-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-size: 13px;
  color: #333;
}

.context-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.context-menu ul li {
  padding: 8px 12px;
  cursor: pointer;
}

.context-menu ul li:hover {
  background-color: #f0f0f0;
}


</style>
