<template>
  <div>
    <div class="search-container">
      <input
        type="text"
        placeholder="Cerca..."
        v-model="searchText"
        @input="onFilterTextBoxChanged"
      />
      <button @click="clearSearch" class="cancelsmall-button">
        <i class="fas fa-times"></i>
      </button>
      <button @click="toggleColumnDialog" class="settingtable-button">
        <i class="fas fa-cogs"></i>
      </button>
      <button @click="exportToExcel" class="excel-button">
        <i class="fas fa-file-excel"></i>
      </button>
      <button @click="exportToExcel" class="share-button">
        <i class="fas fa-share-alt"></i>
      </button>
      <button @click="exportToExcel" class="add-button">
        <i class="fas fa-plus"></i>
      </button>
    </div>
    <div class="grid-container">
      <ag-grid-vue
        class="ag-theme-balham"
        style="width: 100%; height: 100%"
        :columnDefs="columnDefs"
        :rowData="rowData"
        :defaultColDef="defaultColDef"
        @grid-ready="onGridReady"
        @first-data-rendered="onFirstDataRendered"
        :headerHeight="40"
        :rowHeight="30"
        :localeText="localeText"
        :pagination="pagination"
        :paginationPageSize="paginationPageSize"
        :paginationPageSizeSelector="paginationPageSizeSelector"
        :suppressDragLeaveHidesColumns="true"
      ></ag-grid-vue>
    </div>
    <div v-if="isColumnDialogOpen" class="column-dialog" ref="dialog">
      <div class="column-dialog-content draggable" @mousedown="mouseDownHandler">
        <h3>Mostra/Nascondi Colonne</h3>
        <div>
          <input
            type="checkbox"
            id="selectAll"
            :checked="allColumnsVisible"
            @change="toggleAllColumns"
          />
          <label for="selectAll">All chek/uncheck</label>
        </div>
        <div v-for="col in columnDefs" :key="col.field">
          <input
            type="checkbox"
            :id="col.field"
            :checked="!col.hide"
            @change="toggleColumn(col)"
          />
          <label :for="col.field">{{ col.field }}</label>
        </div>
        <div class="spacer"></div>
        <div class="close-button-container">
          <button @click="closeColumnDialog" class="close-button">
            <i class="fas fa-times"></i>
            CHIUDI
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, onSnapshot } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDXeyRUFVUGqhGz-HKg89znV9cvMkUUMAM",
  authDomain: "fluttere-distribuzione.firebaseapp.com",
  projectId: "fluttere-distribuzione",
  storageBucket: "fluttere-distribuzione.appspot.com",
  messagingSenderId: "195729499651",
  appId: "1:195729499651:web:cb2b353eed6bc888f85036"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default {
  name: "PageProgetti",
  components: {
    AgGridVue,
  },
  data() {
    return {
      localeText: {
        selectAll: 'Seleziona tutto',
        selectAllSearchResults: 'Seleziona tutti i risultati della ricerca',
        searchOoo: 'Cerca...',
        blanks: 'Vuoti',
        noMatches: 'Nessun risultato',
        filterOoo: 'Filtra...',
        equals: 'Uguale',
        notEqual: 'Diverso',
        empty: 'Scegli uno',
        blank: 'Vuoto', 
        notBlank: 'Non vuoto',
        lessThan: 'Minore di',
        greaterThan: 'Maggiore di',
        lessThanOrEqual: 'Minore o uguale a',
        greaterThanOrEqual: 'Maggiore o uguale a',
        inRange: 'Nel range',
        inRangeStart: 'da',
        inRangeEnd: 'a',
        contains: 'Contiene',
        notContains: 'Non contiene',
        startsWith: 'Inizia con',
        endsWith: 'Finisce con',
        dateFormatOoo: 'gg/mm/aaaa',
        andCondition: 'E',
        orCondition: 'O',
        applyFilter: 'Applica',
        resetFilter: 'Reimposta',
        clearFilter: 'Annulla',
        cancelFilter: 'Annulla',
        columns: 'Colonne',
        filters: 'Filtri',
        pivotMode: 'Modalità pivot',
        groups: 'Gruppi di righe',
        rowGroupColumnsEmptyMessage: 'Trascina qui per impostare i gruppi di righe',
        values: 'Valori',
        valueColumnsEmptyMessage: 'Trascina qui per aggregare',
        pivots: 'Etichette di colonne',
        pivotColumnsEmptyMessage: 'Trascina qui per impostare le etichette delle colonne',
        pinColumn: 'Fissa colonna',
        valueAggregation: 'Aggregazione valori',
        autosizeThiscolumn: 'Adatta automaticamente questa colonna',
        autosizeAllColumns: 'Adatta automaticamente tutte le colonne',
        groupBy: 'Raggruppa per',
        ungroupBy: 'Separa per',
        resetColumns: 'Reimposta colonne',
        expandAll: 'Espandi tutto',
        collapseAll: 'Comprimi tutto',
        copy: 'Copia',
        ctrlC: 'Ctrl+C',
        copyWithHeaders: 'Copia con intestazioni',
        copyWithGroupHeaders: 'Copia con intestazioni di gruppo',
        paste: 'Incolla',
        ctrlV: 'Ctrl+V',
        export: 'Esporta',
        csvExport: 'Esporta in CSV',
        excelExport: 'Esporta in Excel',
        pivotChartAndPivotMode: 'Grafico pivot e modalità pivot',
        pivotChart: 'Grafico pivot',
        chartRange: 'Intervallo grafico',
        columnChart: 'Colonna',
        groupedColumn: 'Colonna raggruppata',
        stackedColumn: 'Colonna impilata',
        normalizedColumn: '100% colonna impilata',
        barChart: 'Barra',
        groupedBar: 'Barra raggruppata',
        stackedBar: 'Barra impilata',
        normalizedBar: '100% barra impilata',
        pieChart: 'Torta',
        pie: 'Torta',
        doughnut: 'Ciambella',
        line: 'Linea',
        xyChart: 'XY (Scatter)',
        scatter: 'Scatter',
        bubble: 'Bolla',
        areaChart: 'Area',
        area: 'Area',
        stackedArea: 'Area impilata',
        normalizedArea: '100% area impilata',
        histogramChart: 'Istogramma',
        pivotChartTitle: 'Grafico pivot',
        rangeChartTitle: 'Grafico dell\'intervallo',
        settings: 'Impostazioni',
        data: 'Dati',
        format: 'Formato',
        categories: 'Categorie',
        series: 'Serie',
        xyValues: 'Valori XY',
        paired: 'Modalità accoppiata',
        axis: 'Asse',
        color: 'Colore',
        thickness: 'Spessore',
        xRotation: 'Rotazione X',
        yRotation: 'Rotazione Y',
        ticks: 'Ticks',
        width: 'Larghezza',
        height: 'Altezza',
        length: 'Lunghezza',
        padding: 'Padding',
        chart: 'Grafico',
        title: 'Titolo',
        background: 'Sfondo',
        font: 'Font',
        top: 'Sopra',
        right: 'Destra',
        bottom: 'Sotto',
        left: 'Sinistra',
        labels: 'Etichette',
        size: 'Dimensione',
        legend: 'Legenda',
        position: 'Posizione',
        markerSize: 'Dimensione marker',
        markerStroke: 'Spessore marker',
        markerPadding: 'Padding marker',
        itemPaddingX: 'Padding oggetto X',
        itemPaddingY: 'Padding oggetto Y',
        strokeWidth: 'Spessore linea',
        offset: 'Offset',
        tooltips: 'Tooltips',
        offsets: 'Offsets',
        callout: 'Callout',
        markers: 'Marker',
        shadow: 'Ombra',
        blur: 'Sfocatura',
        xOffset: 'Offset X',
        yOffset: 'Offset Y',
        lineWidth: 'Spessore linea',
        normal: 'Normale',
        bold: 'Grassetto',
        italic: 'Italico',
        boldItalic: 'Grassetto Italico',
        fillOpacity: 'Opacità riempimento',
        strokeOpacity: 'Opacità contorno',
        columnGroup: 'Colonna',
        barGroup: 'Barra',
        pieGroup: 'Torta',
        lineGroup: 'Linea',
        scatterGroup: 'Scatter',
        areaGroup: 'Area',
        histogramGroup: 'Istogramma',
        groupedColumnTooltip: 'Raggruppati',
        stackedColumnTooltip: 'Impilati',
        normalizedColumnTooltip: '100% Impilati',
        groupedBarTooltip: 'Raggruppati',
        stackedBarTooltip: 'Impilati',
        normalizedBarTooltip: '100% Impilati',
        pieTooltip: 'Torta',
        doughnutTooltip: 'Ciambella',
        lineTooltip: 'Linea',
        groupedAreaTooltip: 'Raggruppati',
        stackedAreaTooltip: 'Impilati',
        normalizedAreaTooltip: '100% Impilati',
        scatterTooltip: 'Scatter',
        bubbleTooltip: 'Bolla',
        histogramTooltip: 'Istogramma',
        noDataToChart: 'Nessun dato disponibile per creare il grafico.',
        pivotChartRequiresPivotMode: 'Il grafico pivot richiede la modalità pivot abilitata.',
        chartSettingsToolbarTooltip: 'Menu',
        chartLinkToolbarTooltip: 'Collegato alla griglia',
        chartUnlinkToolbarTooltip: 'Scollegato dalla griglia',
        chartDownloadToolbarTooltip: 'Download grafico',
        ariaHidden: 'nascosto',
        ariaVisible: 'visibile',
        ariaChecked: 'selezionato',
        ariaUnchecked: 'deselezionato',
        ariaIndeterminate: 'indeterminato',
        ariaDefaultListName: 'Elenco',
        ariaColumnSelectAll: 'Seleziona tutte le colonne',
        ariaInputEditor: 'Editor input',
        ariaDateFilterInput: 'Input filtro data',
        ariaFilterList: 'Elenco filtro',
        ariaFilterInput: 'Input filtro',
        ariaFilterColumnsInput: 'Input colonne filtro',
        ariaFilterValue: 'Valore filtro',
        ariaFilterFromValue: 'Filtro dal valore',
        ariaFilterToValue: 'Filtro al valore',
        ariaFilteringOperator: 'Operatore di filtro',
        ariaColumnToggleVisibility: 'Visibilità colonna attiva',
        ariaColumnGroupToggleVisibility: 'Visibilità gruppo colonne attiva',
        ariaRowSelect: 'Premi SPAZIO per selezionare questa riga',
        ariaRowDeselect: 'Premi SPAZIO per deselezionare questa riga',
        ariaRowToggleSelection: 'Premi SPAZIO per cambiare selezione riga',
        ariaRowSelectAll: 'Premi SPAZIO per cambiare selezione tutte le righe',
        ariaSearch: 'Cerca',
        ariaSearchFilterValues: 'Cerca valori filtro'
      },
      columnDefs: [],
      rowData: [],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      searchText: "",
      gridApi: null,
      gridColumnApi: null,
      isColumnDialogOpen: false,
      allColumnsVisible: true,
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      onSnapshot(collection(db, "progetti"), (querySnapshot) => {
        let users = [];
        let allFields = new Set();
        
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
          Object.keys(doc.data()).forEach((key) => allFields.add(key));
        });

        const columnDefs = Array.from(allFields).sort().map((field) => ({ field, hide: false }));
        this.columnDefs = columnDefs;

        users = users.map(user => {
          const filledUser = {};
          allFields.forEach(field => {
            filledUser[field] = user[field] || '';
          });
          return filledUser;
        });

        this.rowData = users;
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.pagination = true;
      this.paginationPageSize = 50;
      this.paginationPageSizeSelector = [50, 60, 70, 100, 300, 500, 1000];
    },
    onFirstDataRendered() {
      if (this.gridColumnApi) {
        const allColumns = this.gridColumnApi.getAllColumns();
        allColumns.forEach((column) => {
          this.gridColumnApi.setColumnWidth(column, 200);
        });
        this.gridApi.sizeColumnsToFit();
      }
    },
    onFilterTextBoxChanged() {
      this.gridApi.setGridOption('quickFilterText', this.searchText);
    },
    clearSearch() {
      this.searchText = "";
      this.gridApi.setGridOption('quickFilterText', '');
    },
    exportToExcel() {
      const rowData = [];
      this.gridApi.forEachNode(node => rowData.push(node.data));
      
      const worksheet = XLSX.utils.json_to_sheet(rowData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: "application/octet-stream" });
      
      const now = new Date();
      const timestamp = now.toISOString().replace(/[-T:.Z]/g, '').slice(0, 14);
      const filename = `export_${timestamp}.xlsx`;
      
      saveAs(data, filename);
    },
    closeColumnDialog() {
      this.isColumnDialogOpen = false;
    },
    toggleColumnDialog() {
      this.isColumnDialogOpen = !this.isColumnDialogOpen;
      this.checkAllColumnsVisibility();
    },
    toggleColumn(col) {
      col.hide = !col.hide;
      if (this.gridColumnApi) {
        this.gridColumnApi.setColumnVisible(col.field, !col.hide);
      }
      this.checkAllColumnsVisibility();
    },
    toggleAllColumns(event) {
      const isVisible = event.target.checked;
      this.allColumnsVisible = isVisible;
      this.columnDefs.forEach((col) => {
        col.hide = !isVisible;
        if (this.gridColumnApi) {
          this.gridColumnApi.setColumnVisible(col.field, isVisible);
        }
      });
    },
    checkAllColumnsVisibility() {
      this.allColumnsVisible = this.columnDefs.every(col => !col.hide);
    },
    mouseDownHandler(event) {
      const dialog = this.$refs.dialog;
      if (!dialog) return;

      const offsetX = event.clientX - dialog.offsetLeft;
      const offsetY = event.clientY - dialog.offsetTop;

      const mouseMoveHandler = (moveEvent) => {
        dialog.style.left = `${moveEvent.clientX - offsetX}px`;
        dialog.style.top = `${moveEvent.clientY - offsetY}px`;
      };

      const mouseUpHandler = () => {
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    }
  },
};
</script>

<style scoped>
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-balham.css";
/* Import Font Awesome for icons */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

.search-container {
  margin-bottom: 10px;
}

input[type="text"] {
  padding: 8px;
  font-size: 16px;
  box-sizing: border-box;
  margin-right: 10px;
  width: 300px;
}

button {
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
}

.excel-button, .settingtable-button, .share-button, .add-button, .cancelsmall-button {
  background-color: rgba(0,0,0,0);
  color: #7A929E;
  border: none;
  padding: 8px;
}

.excel-button .fas, .settingtable-button .fas, .share-button .fas, .add-button .fas {
  font-size: 22px;
}

.cancelsmall-button .fas {
  font-size: 16px;
}

.grid-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 87.2vh;
  width: 100%;
}


.column-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 600px;
  min-width: 350px;
  overflow-y: auto;
}

.column-dialog-content {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
}

.draggable {
  cursor: move;
}

.spacer {
  height: 20px;
}

.close-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.close-button {
  font-size: 13px;
  font-weight: bold;
  color: #787878;
  background-color: transparent;
  border: none;
}

.column-dialog h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #787878;
  text-align: center;
  width: 100%;
  user-select: none;
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

input[type="checkbox"] + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 14px;
  color: #787878;
}

input[type="checkbox"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 11px;
  height: 11px;
  border: 2px solid #7A929E;
  border-radius: 3px;
  background-color: #fff;
  transition: background-color 0.3s, border-color 0.3s;
  font-size: 12px;
}

input[type="checkbox"]:checked + label:before {
  background-color: #7A929E;
  border-color: #7A929E;
}

input[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
</style>


