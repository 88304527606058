<template>
  <div>
    <div class="search-container">
      <input
        type="text"
        placeholder="Cerca..."
        v-model="searchText"
        @input="onFilterTextBoxChanged"
      />
      <button @click="clearSearch" class="cancelsmall-button">
        <i class="fas fa-times"></i>
      </button>
      <button @click="toggleColumnDialog" class="settingtable-button">
        <i class="fas fa-cogs"></i>
      </button>
      <button @click="exportToExcel" class="excel-button">
        <i class="fas fa-file-excel"></i>
      </button>
      <button @click="exportToExcel" class="share-button">
        <i class="fas fa-share-alt"></i>
      </button>
   <!--    <button @click="addItem" class="add-item-button">
        <i class="fas fa-plus"></i>
      </button>-->
    </div>
    <div class="tabs-container">
      <button @click="changeTab('workspace')" :class="{ active: selectedTab === 'workspace' }">
        <i class="fa fa-home" style="margin-right: 6px;"></i>
        ALL WORKSPACES
      </button>
      <button @click="addColumn" class="add-column-button"><i class="fas fa-columns" style="margin-right: 6px;"></i> Aggiungi Colonna</button>
      <button @click="addItem" class="add-row-button">
        <i class="fas fa-plus" style="margin-right: 6px;"></i> {{ buttonLabel }}
      </button>
      <span v-if="selectedTab === 'single'" class="workspace-name">
        Workspace: 
        <span v-if="!isLoadingWorkspaceName">{{ currentWorkspaceName }}</span>
        <span v-else>Caricamento...</span>
      </span>
    </div>
    <div v-if="selectedTab === 'workspace'" class="grid-container">
      <ag-grid-vue
        class="ag-theme-balham"
        style="width: 100%; height: 100%"
        :columnDefs="columnDefsCommesse"
        :rowData="rowDataCommesse"
        :defaultColDef="defaultColDef"
        @columnMoved="onColumnMovedDebounced"
        @columnResized="onColumnResizedDebounced"
        @gridReady="onGridReadyCommesse"
        @cellValueChanged="onCellValueChanged"
        :singleClickEdit="false"
        :headerHeight="40"
        :rowHeight="30"
        :localeText="localeText"
        :pagination="pagination"
        :paginationPageSize="paginationPageSize"
        :paginationPageSizeSelector="paginationPageSizeSelector"
        :suppressDragLeaveHidesColumns="true"
      ></ag-grid-vue>
    </div>
<div v-if="selectedTab === 'single'" class="grid-container">
    <ag-grid-vue
      class="ag-theme-balham"
      style="width: 100%; height: 100%"
      @columnMoved="onColumnMovedDebounced"
      @columnResized="onColumnResizedDebounced"
      @cellValueChanged="onCellValueChangedSingle"
      :singleClickEdit="false"
      :headerHeight="40"
      :rowHeight="30"
      :localeText="localeText"
      :pagination="pagination"
      :paginationPageSize="paginationPageSize"
      :paginationPageSizeSelector="paginationPageSizeSelector"
      :suppressDragLeaveHidesColumns="true"
      :columnDefs="columnDefsProgetti"
      :rowData="rowDataProgetti"
      :defaultColDef="defaultColDef"
      @gridReady="onGridReadyProgetti"
    ></ag-grid-vue>


</div>

    <div v-if="isColumnDialogOpen" class="column-dialog" ref="dialog">
      <div class="column-dialog-content draggable" @mousedown="mouseDownHandler">
        <h3>Mostra/Nascondi Colonne</h3>
        <div>
          <input
            type="checkbox"
            id="selectAll"
            :checked="allColumnsVisible"
            @change="toggleAllColumns"
          />
          <label for="selectAll">All check/uncheck</label>
        </div>
<div v-for="col in filteredColumnDefs" :key="col.field">
  <input
    type="checkbox"
    :id="col.field"
    :checked="!col.hide"
    @change="toggleColumn(col)"
  />
  <label :for="col.field">{{ col.field }}</label>
</div>
        <div class="spacer"></div>
        <div class="close-button-container">
          <button @click="closeColumnDialog" class="close-button">
            <i class="fas fa-times"></i>
            CHIUDI
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { AgGridVue } from "ag-grid-vue3";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, onSnapshot, doc, setDoc, updateDoc, getDoc, getDocs, addDoc } from "firebase/firestore";
import eventBus from './eventBus.js';

const firebaseConfig = {
  apiKey: "AIzaSyDXeyRUFVUGqhGz-HKg89znV9cvMkUUMAM",
  authDomain: "fluttere-distribuzione.firebaseapp.com",
  projectId: "fluttere-distribuzione",
  storageBucket: "fluttere-distribuzione.appspot.com",
  messagingSenderId: "195729499651",
  appId: "1:195729499651:web:cb2b353eed6bc888f85036"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function debounce(func, wait) {
  let timeout;
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export default {
  name: "PageCommesse",
  components: {
    AgGridVue,
  },

data() {
  return {
    columnDefsCommesse: [],
    columnDefsProgetti: [],
    rowDataCommesse: [],
    rowDataProgetti: [],
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
      editable: true,
    },
    searchText: "",
    gridApiCommesse: null,
    gridApiProgetti: null,
    gridColumnApiCommesse: null,
    gridColumnApiProgetti: null,
    isColumnDialogOpen: false,
    allColumnsVisible: true,
    selectedTab: 'workspace',
    newItemId: null,
    isLoadingData: false,
    isGridVisible: false,
    isGridReady: false, 
    currentWorkspaceName: '',
    isLoadingWorkspaceName: false,
    isInitialLoad: true, 
    buttonLabel: 'Aggiungi Workspace',
  };
},


beforeDestroy() {
  // Unsubscribe from any real-time listeners (Firebase, for example)
  if (this.unsubscribe) {
    this.unsubscribe();
  }

  // Remove the event listener from the event bus
  eventBus.off('cliccatoChanged', this.handleCliccatoChange);

  // Clean up window event listeners if added
  window.handleRowButtonClick = null;

  // If you had any intervals or timeouts, clear them here
  if (this.someTimer) {
    clearTimeout(this.someTimer);
  }
},



  mounted() {
    window.handleRowButtonClick = this.handleRowButtonClick.bind(this);
    //console.log("Mounted: rowDataCommesse", this.rowDataCommesse);
    eventBus.on('cliccatoChanged', this.handleCliccatoChange); // Registering event listener correctly
    //this.checkCliccato();
  },

  created() {
    // Carica prima lo stato delle colonne

    this.loadColumnState().then(() => {
        // Ora che gli stati delle colonne sono caricati, carica i dati
        const userId = localStorage.getItem('userId');
        if (userId) {
            this.fetchData(`wk-${userId}`, 'rowDataCommesse');
        } else {
            console.error('User ID non trovato nel localStorage.');
        }
        this.fetchData('progetti', 'rowDataProgetti');
    });

    this.onColumnMovedDebounced = debounce(this.onColumnMoved, 300);
    this.onColumnResizedDebounced = debounce(this.onColumnResized, 300);
  },

  methods: {
    

async handleCliccatoChange(newValue) {
    var tempItemId = 'workspace';
    tempItemId = localStorage.getItem('cliccato');

    if (tempItemId !== 'workspace') {
        this.selectedTab = 'single';
        this.newItemId = tempItemId;

        // Resetta lo stato della griglia e delle colonne
        this.rowDataProgetti = [];
        this.columnDefsProgetti = [];
        this.gridApiProgetti?.setRowData([]); 
        this.gridApiProgetti?.setColumnDefs([]);
        this.currentWorkspaceName = ''; 
        this.isGridReady = false; // Indica che la griglia non è ancora pronta
        // Cambia l'etichetta del pulsante a "Aggiungi Attività"
        this.buttonLabel = 'Aggiungi Attività';
        // Carica lo stato delle colonne per il documento selezionato
        const stateLoaded = await this.loadColumnState(false);
        if (!stateLoaded) {
            console.error("Errore nel caricamento dello stato delle colonne");
            return;
        }

        // Ora che lo stato delle colonne è caricato, carica i dati del workspace
        this.isLoadingData = true;
        this.isLoadingWorkspaceName = true;

        try {
            const userId = localStorage.getItem('userId');
            if (!userId || typeof userId !== 'string') {
                console.error('Errore: User ID non trovato o non è una stringa valida.', userId);
                return;
            }

            const collectionRef = collection(db, tempItemId);  // La collezione ha come nome l'ID
            const querySnapshot = await getDocs(collectionRef);

            if (!querySnapshot.empty) {
                const data = [];
                querySnapshot.forEach(doc => {
                    data.push(doc.data());
                });

                if (data.length > 0) {
                    this.rowDataProgetti = data;
                    console.log('Dati caricati:', this.rowDataProgetti);

                    // Verifica che la griglia sia pronta prima di impostare i dati
                    if (this.gridApiProgetti) {
                        this.gridApiProgetti.setRowData(this.rowDataProgetti);
                        console.log('Dati impostati nella griglia');
                    } else {
                        console.error('gridApiProgetti non è pronta.');
                    }
                } else {
                    console.error("Il documento non contiene dati.");
                }
            } else {
                console.error("Nessun documento trovato per questo ID!");
            }

            // Carica il nome del workspace
            const workspaceDoc = await getDoc(doc(db, `wk-${userId}`, tempItemId));
            if (workspaceDoc.exists()) {
                this.currentWorkspaceName = workspaceDoc.data().nome || 'Nome non disponibile';
                console.log('Nome del workspace:', this.currentWorkspaceName);
            } else {
                this.currentWorkspaceName = 'Nome non disponibile';
                console.log('Workspace non trovato');
            }

            this.isLoadingData = false;
        } catch (error) {
            console.error("Errore durante il recupero del documento:", error);
            this.isLoadingData = false;
        } finally {
            this.isLoadingWorkspaceName = false; // Imposta il caricamento del nome come completato
            this.isGridReady = true; // Segnala che la griglia è pronta
            this.$forceUpdate(); // Forza l'aggiornamento della UI per garantire la visualizzazione del nome del workspace
        }

    } else {
        this.changeTab('workspace');      
    }
},






       changeTab(tab) {
        this.selectedTab = tab;

        if (tab === 'workspace') {
            // Resetta i dati e le colonne della tabella 'single'
            this.rowDataProgetti = [];
            this.columnDefsProgetti = [];
            this.gridApiProgetti?.setRowData([]); 
            this.gridApiProgetti?.setColumnDefs([]);
            this.currentWorkspaceName = ''; // Resetta il nome del workspace
            this.buttonLabel = 'Aggiungi Workspace'; // Torna al testo iniziale del pulsante
        } else if (tab === 'single') {
            this.buttonLabel = 'Aggiungi Attività'; // Cambia il testo del pulsante
            this.rowDataProgetti = [];
            this.columnDefsProgetti = [];
            this.gridApiProgetti?.setRowData([]); // Svuota i dati nella griglia
            this.gridApiProgetti?.setColumnDefs([]); // Svuota le colonne nella griglia
            this.currentWorkspaceName = ''; // Resetta il nome del workspace
            this.loadColumnState(false);
        }
        
        // Carica lo stato delle colonne per la nuova tab
        this.isGridReady = false; // Resetta lo stato di caricamento della griglia
        this.loadColumnState().then(() => {
            // Dopo aver caricato lo stato delle colonne, carica i dati associati
            const userId = localStorage.getItem('userId');
            if (userId) {
                if (tab === 'workspace') {
                    this.fetchData(`wk-${userId}`, 'rowDataCommesse');
                } else if (tab === 'single' && this.newItemId) {
                    this.fetchData(`single-${this.newItemId}`, 'rowDataProgetti');
                }
            } else {
                console.error('User ID non trovato nel localStorage.');
            }
        });
    },



  beforeUnmount() {
    // Rimuovi l'ascoltatore dell'evento quando il componente viene distrutto
    //eventBus.off('cliccatoChanged', this.handleCliccatoChange);
    if (this.unsubscribe) {
        this.unsubscribe();
    }
  },

async loadColumnState(forceReload = false) {
  try {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.error('User ID not found.');
      return false; // Ritorna false se manca l'ID utente
    }

    // Usa wk-XXXXXX come nome della collezione
    const collectionName = `wk-${userId}`;
    
    // Definisce l'ID del documento in base alla tab selezionata
    const stateDocId = this.selectedTab === 'workspace' 
                       ? `wk-${userId}` // Documento per la tab workspace
                       : `single-${this.newItemId}`; // Documento per la tab single

    console.log(`Stato delle colonne per docId: ${stateDocId}`);

    // Riferimento al documento nella collezione dinamica
    const stateDocRef = doc(db, collectionName, stateDocId);

    const docSnapshot = await getDoc(stateDocRef);

    if (docSnapshot.exists() || forceReload) {
      const stateData = docSnapshot.exists() ? docSnapshot.data() : {};
      console.log('Stato delle colonne caricato:', stateData);

      // Assicurati che lo stato delle colonne sia valido
      if (stateData && stateData.colonne) {
        this.setupAgGridColumns(stateData);
        return true;
      } else {
        console.error('Stato delle colonne non valido o mancante.');
        return false;
      }
    } else {
      console.warn("Documento di stato non trovato!");
      return false;
    }
  } catch (error) {
    console.error("Errore durante il caricamento dello stato delle colonne:", error);
    return false;
  }
},


async addColumn() {
  const userId = localStorage.getItem('userId');
  if (!userId) {
    console.error('User ID non trovato.');
    return;
  }

  const newColumnName = prompt("Inserisci il nome della nuova colonna:");
  if (!newColumnName) {
    return;
  }

  try {
    const documentId = this.selectedTab === 'single' && this.newItemId
      ? `single-${this.newItemId}`
      : `wk-${userId}`;

    const docRef = doc(db, `wk-${userId}`, documentId);

    const newColumnConfig = {
      [`colonne.${newColumnName}`]: {
        order: 100000,
        size: 200,
        visible: true
      }
    };

    // Aggiorna il documento con la nuova colonna
    await updateDoc(docRef, newColumnConfig);

    // Ricarica lo stato delle colonne
    await this.loadColumnState();

    // Ricarica i dati nella griglia per riflettere la nuova colonna
    if (this.selectedTab === 'single' && this.newItemId) {
     // this.fetchData(`single-${this.newItemId}`, 'rowDataProgetti');
    } else {
      this.fetchData(`wk-${userId}`, 'rowDataCommesse');
    }

    this.$forceUpdate(); // Forza l'aggiornamento della UI

  } catch (error) {
    console.error("Errore durante l'aggiunta della colonna:", error);
  }
},






async addRow() {
  if (!this.newItemId) {
    console.error("ID del documento non è impostato.");
    return;
  }

  try {
    const docRef = doc(db, "workspace", this.newItemId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data().data || [];
      const emptyRow = {};

      // Inizializza ogni colonna con un valore vuoto
      if (data.length > 0) {
        Object.keys(data[0]).forEach(key => {
          emptyRow[key] = '';
        });
      }

      data.push(emptyRow); // Aggiungi la nuova riga all'array
      await updateDoc(docRef, { data });
      //console.log("Riga vuota aggiunta con successo!");
    } else {
      console.error("Documento non trovato.");
    }
  } catch (error) {
    console.error("Errore durante l'aggiunta della riga vuota:", error);
  }
},

async handleRowButtonClick(documentId) {
    // Cancella eventuali listener esistenti per evitare duplicazioni
    if (this.unsubscribe) {
        this.unsubscribe();
    }

    // Imposta la tab selezionata come 'single'
    this.selectedTab = 'single';
    this.newItemId = documentId;
    this.currentWorkspaceName = ''; 
    this.isGridVisible = false;  
    this.isLoadingData = true;
    this.isLoadingWorkspaceName = true;
    
    // Cambia l'etichetta del pulsante a "Aggiungi Attività"
    this.buttonLabel = 'Aggiungi Attività';

    try {
        // Configura il listener per la collezione 'single-XXX'
        const collectionRef = collection(db, documentId);

        this.unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
            const data = [];
            querySnapshot.forEach(doc => {
                data.push(doc.data());
            });

            // Aggiorna i dati della tabella 'single'
            this.rowDataProgetti = data;
            if (this.gridApiProgetti) {
                this.gridApiProgetti.setRowData(this.rowDataProgetti);
            }
        });

        // Carica lo stato delle colonne per il documento selezionato
        const stateLoaded = await this.loadColumnState(false);
        if (!stateLoaded) {
            console.error("Errore nel caricamento dello stato delle colonne");
            return;
        }

        // Carica il nome del workspace
        const userId = localStorage.getItem('userId');
        if (!userId || typeof userId !== 'string') {
            console.error('Errore: User ID non trovato o non è una stringa valida.', userId);
            return;
        }

        const workspaceDoc = await getDoc(doc(db, `wk-${userId}`, documentId));
        if (workspaceDoc.exists()) {
            this.currentWorkspaceName = workspaceDoc.data().nome || 'Nome non disponibile';
            console.log('Nome del workspace:', this.currentWorkspaceName);
        } else {
            this.currentWorkspaceName = 'Nome non disponibile';
            console.log('Workspace non trovato');
        }

        this.isLoadingData = false;
    } catch (error) {
        console.error("Errore durante il recupero del documento:", error);
        this.isLoadingData = false;
    } finally {
        this.isLoadingWorkspaceName = false; // Imposta il caricamento come completato
        this.isGridVisible = true; // Mostra di nuovo la griglia
    }
},




    async createAndSaveDefaultColumnState(stateDocRef) {
        // Verifica se il documento di stato esiste già
        const docSnap = await getDoc(stateDocRef);
        if (docSnap.exists()) {
            //console.log("Documento di stato esistente, nessuna creazione necessaria.");
            return; // Esci se il documento esiste già
        }

        // Definisci le colonne di default
        const defaultColumnDefs = [
            //  { field: 'colonna1', headerName: 'Colonna 1', editable: true, sortable: true, filter: true, resizable: true },
            //  { field: 'colonna2', headerName: 'Colonna 2', editable: true, sortable: true, filter: true, resizable: true },
            //  Aggiungi altre colonne di default se necessario
        ];

        this.columnDefsProgetti = defaultColumnDefs;

        if (this.gridApiProgetti) {
            this.gridApiProgetti.setColumnDefs(this.columnDefsProgetti);
        }

        // Crea lo stato delle colonne di default da salvare
        const defaultStateData = {
            colonne: {
                colonna1: { visible: true, size: 200, order: 1 },
                colonna2: { visible: true, size: 200, order: 2 },
                // Aggiungi altre colonne di default se necessario
            }
        };

        // Salva lo stato delle colonne di default nel database
        this.saveColumnState(stateDocRef, defaultStateData);
    },

async saveColumnState(stateDocRef, stateData) {
    try {
        await setDoc(stateDocRef, stateData, { merge: true });
       // console.log("Stato delle colonne di default salvato con successo.");
    } catch (error) {
        console.error("Errore durante il salvataggio dello stato delle colonne di default:", error);
    }
},


fetchData(collectionName, rowDataKey) {
  const colRef = collection(db, collectionName);
  onSnapshot(colRef, (querySnapshot) => {
    let data = [];
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      const id = doc.id;

      if (/^\d+$/.test(id)) {  // Filtra solo ID numerici
        docData.id = id;
        data.push(docData);
      }
    });

    // Log dei dati recuperati
    console.log("Dati recuperati:", data);
    
    this.updateRowData(rowDataKey, data);
  }, (error) => {
    console.error("Errore durante il recupero dei dati:", error);
  });
},


setupAgGridColumns(stateData) {
  const columnDefs = [];

  for (const [colKey, colConfig] of Object.entries(stateData.colonne)) {
    let columnDef = {
      field: colKey,
      hide: !colConfig.visible,
      width: colConfig.size,
      sort: colConfig.order,
      editable: colKey !== 'id',
    };

    if (colKey === 'action') {
      columnDef = {
        ...columnDef,
        headerName: '',
        cellRenderer: (params) => {
          if (params.data && params.data.id) {
            return `<button class="action-button" onclick="window.handleRowButtonClick('${params.data.id}')">EDITA</button>`;
          } else {
            return '';
          }
        },
        editable: false,
        width: 70,
        resizable: false,
        sortable: false,
        filter: false,
      };
    }

    columnDefs.push(columnDef);
  }

  columnDefs.sort((a, b) => a.sort - b.sort);

  if (this.selectedTab === 'workspace') {
    this.columnDefsCommesse = columnDefs;
  } else {
    this.columnDefsProgetti = columnDefs;
  }
},





fetchWorkspaceDataAndCreateState(stateDocRef) {
  const userId = localStorage.getItem('userId');
  if (!userId) {
    console.error('User ID not found.');
    return;
  }

  const collectionName = `wk-${userId}`;

  onSnapshot(collection(db, collectionName), (querySnapshot) => {
    const columnDefs = {};

    querySnapshot.forEach((doc) => {
      const data = doc.data();

      Object.keys(data).forEach((field, index) => {
        if (field !== 'colonne') { // Salta la colonna 'colonne'
          if (!columnDefs[field]) {
            columnDefs[field] = {
              visible: true,
              size: 200,
              order: index + 1
            };
          }
        }
      });
    });

    if (Object.keys(columnDefs).length === 0) {
      console.error("Nessun dato trovato in workspace per generare la configurazione delle colonne.");
      return;
    }

    const stateData = { colonne: columnDefs };

    setDoc(stateDocRef, stateData).then(() => {
      //console.log("Documento creato con successo con i dati di workspace!");
      this.setupAgGridColumns(stateData);
    }).catch((error) => {
      console.error("Errore durante la creazione del documento: ", error);
    });
  });
},

async fetchStateData() {
            this.rowDataProgetti = [];
            this.columnDefsProgetti = [];
            this.gridApiProgetti?.setRowData([]); // Svuota i dati nella griglia
            this.gridApiProgetti?.setColumnDefs([]); // Svuota le colonne nella griglia
            this.currentWorkspaceName = ''; // Resetta il nome del workspace


  const userId = localStorage.getItem('userId');
  if (!userId) {
    console.error('User ID not found.');
    return;
  }

  const collectionName = `wk-${userId}`;
  const stateDocId = this.selectedTab === 'workspace' 
                     ? `wk-${userId}` 
                     : `single-${this.newItemId}`; 

  const stateDocRef = doc(db, collectionName, stateDocId);

  try {
    const docSnapshot = await getDoc(stateDocRef);
    if (docSnapshot.exists()) {
      const stateData = docSnapshot.data();
      this.setupAgGridColumns(stateData);
    } else {
      await this.createAndSaveDefaultColumnState(stateDocRef);
    }
  } catch (error) {
    console.error("Errore durante il recupero del documento: ", error);
  }
},





async createAndSaveDefaultColumnState(stateDocRef) {
  // Definisci uno stato di default delle colonne
  const defaultColumnDefs = this.selectedTab === 'workspace'
    ? this.columnDefsCommesse
    : this.columnDefsProgetti;

  const stateData = { colonne: {} };

  // Popola lo stato delle colonne con il default
  defaultColumnDefs.forEach((col, index) => {
    stateData.colonne[col.field] = {
      visible: !col.hide,
      size: col.width || 200,
      order: index + 1,
    };
  });

  // Salva il documento di stato nel database
  try {
    await setDoc(stateDocRef, stateData, { merge: true });
   // console.log("Documento di stato creato con successo con lo stato di default!");
    this.setupAgGridColumns(stateData);
  } catch (error) {
    console.error("Errore durante la creazione del documento di stato: ", error);
  }
},

setupAgGridColumns(stateData) {
  const columnDefs = [];

  for (const [colKey, colConfig] of Object.entries(stateData.colonne)) {
    let columnDef = {
      field: colKey,
      hide: !colConfig.visible,
      width: colConfig.size,
      sort: colConfig.order,
      editable: colKey !== 'id', // Rende non editabile la colonna 'id'
    };

    if (colKey === 'action') {
      columnDef = {
        ...columnDef,
        headerName: '', // Rimuove l'intestazione
        cellRenderer: (params) => {
          if (params.data && params.data.id) {
            return `<button class="action-button" onclick="window.handleRowButtonClick('${params.data.id}')">EDITA</button>`;
          } else {
            return ''; // Ritorna un elemento vuoto o gestisci il caso in cui `params.data.id` non esiste
          }
        },
        editable: false,
        width: 70,
        resizable: false,
        sortable: false,
        filter: false,
      };
    }

    columnDefs.push(columnDef);
  }

  // Ordina le colonne per il loro ordine salvato nel database
  columnDefs.sort((a, b) => a.sort - b.sort);

  if (this.selectedTab === 'workspace') {
    this.columnDefsCommesse = columnDefs;
  } else {
    this.columnDefsProgetti = columnDefs;
  }

  //console.log("ColumnDefs impostati:", columnDefs);
},


updateRowData(rowDataKey, data) {
  if (this.selectedTab === 'workspace' && this.gridApiCommesse) {
    const currentFilterModel = this.gridApiCommesse.getFilterModel();
    this[rowDataKey] = data;
    this.$nextTick(() => {
      this.gridApiCommesse.setRowData(this[rowDataKey]);
      this.gridApiCommesse.setFilterModel(currentFilterModel);
    });
  } else if (this.selectedTab === 'single' && this.gridApiProgetti) {
    const currentFilterModel = this.gridApiProgetti.getFilterModel();
    this[rowDataKey] = data;
    this.$nextTick(() => {
      this.gridApiProgetti.setRowData(this[rowDataKey]);
      this.gridApiProgetti.setFilterModel(currentFilterModel);
    });
  } else {
    this[rowDataKey] = data;
  }
},


    async onGridReadyCommesse(params) {
      this.gridApiCommesse = params.api;
      this.gridColumnApiCommesse = params.columnApi;
      this.pagination = true;
      this.paginationPageSize = 50;
      this.paginationPageSizeSelector = [50, 60, 70, 100, 300, 500, 1000];
    },

async onGridReadyProgetti(params) {
  this.gridApiProgetti = params.api;
  this.gridColumnApiProgetti = params.columnApi;

  // Carica lo stato delle colonne
  await this.fetchStateData(); 

  if (this.columnDefsProgetti.length > 0) {
    this.gridApiProgetti.setColumnDefs(this.columnDefsProgetti);

    const columnState = this.columnDefsProgetti.map((col, index) => ({
      colId: col.field,
      hide: col.hide,
      width: col.width,
      sort: col.sort,
      sortIndex: col.sortIndex !== undefined ? col.sortIndex : index,
    }));

    this.gridApiProgetti.setColumnState(columnState);
  }

  // Imposta isGridReady solo una volta che tutto è pronto
  this.isGridReady = true;
},






    onFilterTextBoxChanged() {
      if (this.selectedTab === 'workspace') {
        this.gridApiCommesse.setQuickFilter(this.searchText);
      } else {
        this.gridApiProgetti.setQuickFilter(this.searchText);
      }
    },

    clearSearch() {
      this.searchText = "";
      if (this.selectedTab === 'workspace') {
        this.gridApiCommesse.setQuickFilter('');
      } else {
        this.gridApiProgetti.setQuickFilter('');
      }
    },

    exportToExcel() {
      const rowData = [];
      const gridApi = this.selectedTab === 'workspace' ? this.gridApiCommesse : this.gridApiProgetti;
      gridApi.forEachNode(node => rowData.push(node.data));

      const worksheet = XLSX.utils.json_to_sheet(rowData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: "application/octet-stream" });

      const now = new Date();
      const timestamp = now.toISOString().replace(/[-T:.Z]/g, '').slice(0, 14);
      const filename = `export_${timestamp}.xlsx`;

      saveAs(data, filename);
    },

    closeColumnDialog() {
      this.isColumnDialogOpen = false;
    },

    toggleColumnDialog() {
      this.isColumnDialogOpen = !this.isColumnDialogOpen;
      this.checkAllColumnsVisibility();
    },

toggleColumn(col) {
  col.hide = !col.hide;
  const gridColumnApi = this.selectedTab === 'workspace' ? this.gridColumnApiCommesse : this.gridColumnApiProgetti;
  if (gridColumnApi) {
    gridColumnApi.setColumnVisible(col.field, !col.hide);
  }
  this.checkAllColumnsVisibility();

  // Salva la visibilità aggiornata in Firebase
  this.saveColumnVisibilityState(col.field, !col.hide);
},

async saveColumnVisibilityState(columnField, isVisible) {
  const userId = localStorage.getItem('userId');
  if (!userId) {
    console.error('User ID not found.');
    return;
  }

  // Usa wk-XXXXXX come nome della collezione
  const collectionName = `wk-${userId}`;

  // Definisce l'ID del documento in base alla tab selezionata
  const stateDocId = this.selectedTab === 'workspace' 
                     ? `wk-${userId}` // Documento per la tab workspace
                     : `single-${this.newItemId}`; // Documento per la tab single

  const stateDocRef = doc(db, collectionName, stateDocId);

  try {
    const stateDoc = await getDoc(stateDocRef);
    let stateData = {};

    if (stateDoc.exists()) {
      stateData = stateDoc.data();
      if (!stateData.colonne) {
        stateData.colonne = {};
      }
    } else {
      // Se il documento non esiste, inizializzalo
      console.warn("Documento di stato non trovato. Creazione di un nuovo documento.");
      stateData = { colonne: {} };
    }

    // Aggiorna la visibilità della colonna
    stateData.colonne[columnField] = {
      ...(stateData.colonne[columnField] || {}),
      visible: isVisible,
    };

    // Salva il documento nel database
    await setDoc(stateDocRef, stateData, { merge: true });
    //console.log(`Visibilità della colonna ${columnField} aggiornata con successo!`);

  } catch (error) {
    console.error("Errore durante il salvataggio dello stato di visibilità della colonna: ", error);
  }
},






    toggleAllColumns(event) {
      const isVisible = event.target.checked;
      this.allColumnsVisible = isVisible;
      const columnDefs = this.selectedTab === 'workspace' ? this.columnDefsCommesse : this.columnDefsProgetti;
      const gridColumnApi = this.selectedTab === 'workspace' ? this.gridColumnApiCommesse : this.gridColumnApiProgetti;
      columnDefs.forEach((col) => {
        col.hide = !isVisible;
        if (gridColumnApi) {
          gridColumnApi.setColumnVisible(col.field, isVisible);
        }
      });
    },

    checkAllColumnsVisibility() {
      const columnDefs = this.selectedTab === 'workspace' ? this.columnDefsCommesse : this.columnDefsProgetti;
      this.allColumnsVisible = columnDefs.every(col => !col.hide);
    },

    mouseDownHandler(event) {
      const dialog = this.$refs.dialog;
      if (!dialog) return;

      const offsetX = event.clientX - dialog.offsetLeft;
      const offsetY = event.clientY - dialog.offsetTop;

      const mouseMoveHandler = (moveEvent) => {
        dialog.style.left = `${moveEvent.clientX - offsetX}px`;
        dialog.style.top = `${moveEvent.clientY - offsetY}px`;
      };

      const mouseUpHandler = () => {
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    },


async onCellValueChanged(event) {
    if (!event || !event.data) {
        console.error("Nessun dato modificato.");
        return;
    }

    const updatedData = event.data;  // I dati aggiornati della riga
    const documentId = updatedData.id;  // Assicurati che ogni riga abbia un ID unico

    if (!documentId) {
        console.error("ID del documento mancante.");
        return;
    }

    // Recupera l'ID utente dal localStorage
    const userId = localStorage.getItem('userId');
    if (!userId) {
        console.error('User ID not found.');
        return;
    }

    const collectionName = `wk-${userId}`;

    try {
        const docRef = doc(db, collectionName, documentId);

        // Recupera il documento dal database
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const currentData = docSnap.data();

            // Verifica se i dati sono cambiati
            if (JSON.stringify(currentData) !== JSON.stringify(updatedData)) {
                await updateDoc(docRef, updatedData);
              //  console.log("Documento aggiornato con successo!");
            } else {
             //   console.log("Nessuna modifica rilevata nei dati.");
            }
        } else {
            console.error("Documento non trovato.");
        }
    } catch (error) {
        console.error("Errore durante l'aggiornamento del documento:", error);
    }
},

async onCellValueChangedSingle(event) {
    if (!event || !event.data) {
        console.error("Nessun dato modificato.");
        return;
    }

    //console.log('event.data:', event.data);  // Debugging: Log event data to check structure

    const updatedData = event.data;  // Dati aggiornati della riga
    const documentId = this.newItemId;  // Usa `newItemId` per identificare il documento singolo

    if (!documentId) {
        console.error("ID del documento non è impostato.");
        return;
    }

    // Recupera l'ID utente dal localStorage
    const userId = localStorage.getItem('userId');
    if (!userId) {
        console.error('User ID not found.');
        return;
    }

    // Verifica che la tab selezionata sia 'single'
    if (this.selectedTab !== 'single') {
        console.error("La tab selezionata non è 'single'.");
        return;
    }

    const docId = updatedData.id;  // Assuming `id` exists in `rowDataProgetti`

    // Debugging logs
    //console.log('collectionName:', documentId);
   // console.log('docId:', docId);

    // Ensure documentId and docId are valid
    if (!documentId || !docId) {
        console.error('Invalid documentId or docId.');
        return;
    }

    try {
        const docRef = doc(db, documentId, docId);

        // Recupera il documento dal database
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const currentData = docSnap.data();

            // Verifica se i dati sono cambiati
            if (JSON.stringify(currentData) !== JSON.stringify(updatedData)) {
                await updateDoc(docRef, updatedData);
                //console.log("Documento aggiornato con successo!");
            } else {
                //console.log("Nessuna modifica rilevata nei dati.");
            }
        } else {
            console.error("Documento non trovato.");
        }
    } catch (error) {
        console.error("Errore durante l'aggiornamento del documento:", error);
    }
},




async onColumnMoved(params) {
  if (!params || !params.api) {
    console.error('API not available');
    return;
  }

  const columnState = params.api.getColumnState();
  if (!columnState) {
    console.error('Column State not available');
    return;
  }

  const stateData = { colonne: {} };

  columnState.forEach((col, index) => {
    stateData.colonne[col.colId] = {
      visible: !col.hide,
      size: col.width,
      order: index + 1,
    };
  });

  const userId = localStorage.getItem('userId');
  if (!userId) {
    console.error('User ID not found.');
    return;
  }

  const collectionName = `wk-${userId}`;
  const stateDocId = this.selectedTab === 'workspace' 
                     ? `wk-${userId}` // Documento per la tab workspace
                     : `single-${this.newItemId}`; // Documento per la tab single

  const stateDocRef = doc(db, collectionName, stateDocId);

  try {
    await setDoc(stateDocRef, stateData, { merge: true });
    //console.log("Ordine delle colonne aggiornato con successo!");
  } catch (error) {
    console.error("Errore durante l'aggiornamento dell'ordine delle colonne: ", error);
  }

},


async onColumnResized(params) {
  if (!params || !params.api) {
    console.error('API not available');
    return;
  }

  const columnState = params.api.getColumnState();
  if (!columnState) {
    console.error('Column State not available');
    return;
  }

  const stateData = { colonne: {} };

  columnState.forEach((col, index) => {
    stateData.colonne[col.colId] = {
      visible: !col.hide,
      size: col.width,
      order: index + 1,
    };
  });

  const userId = localStorage.getItem('userId');
  if (!userId) {
    console.error('User ID not found.');
    return;
  }

  const collectionName = `wk-${userId}`;
  const stateDocId = this.selectedTab === 'workspace' 
                     ? `wk-${userId}` // Documento per la tab workspace
                     : `single-${this.newItemId}`; // Documento per la tab single

  const stateDocRef = doc(db, collectionName, stateDocId);

  try {
    await setDoc(stateDocRef, stateData, { merge: true });
    //console.log("Larghezza delle colonne aggiornata con successo!");
  } catch (error) {
    console.error("Errore durante l'aggiornamento della larghezza delle colonne: ", error);
  }
},




    getCurrentDateLocale() {
      const today = new Date();
      return today.toLocaleDateString();
    },

    async generateTimestampId() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hour = String(now.getHours()).padStart(2, '0');
      const minute = String(now.getMinutes()).padStart(2, '0');
      const second = String(now.getSeconds()).padStart(2, '0');
      const millisecond = String(now.getMilliseconds()).padStart(3, '0');
      return `${year}${month}${day}${hour}${minute}${second}${millisecond}`;
    },

async addItem(event) {
  event.stopPropagation();

  // Recupera l'ID utente dal localStorage
  const userId = localStorage.getItem('userId');
  
  if (!userId) {
    console.error('User ID not found.');
    return;
  }

  if (this.selectedTab === 'workspace') {
    // Logica per aggiungere un nuovo workspace (come già esistente)
    const collectionName = `wk-${userId}`;
    
    const id = await this.generateTimestampId();
    this.newItemId = id;

    const newItem = {
      id: id,
      nome: 'Nuovo workspace',
      dataCreazione: this.getCurrentDateLocale(),
      action: '',
    };

    try {
      await setDoc(doc(db, collectionName, id), newItem);
      const id2 = await this.generateTimestampId();
      await setDoc(doc(db, id, id2), { id: id2, attività: 'attività di default' });
      console.log(`Documento aggiunto con successo alla collezione ${collectionName}`);
    } catch (e) {
      console.error('Errore durante l\'aggiunta del documento:', e);
    }

  } else if (this.selectedTab === 'single' && this.newItemId) {
    // Logica per aggiungere una nuova attività alla collezione 'single-XXX'
    const collectionName = this.newItemId; // Nome della collezione basato sull'ID del workspace
    
    const id = await this.generateTimestampId(); // Genera un nuovo ID univoco per la nuova attività
    const newActivity = {
      id: id,
      attività: 'Nuova attività',
      dataCreazione: this.getCurrentDateLocale(),
      // Aggiungi altri campi necessari per la tua attività qui
    };

    try {
      await setDoc(doc(db, collectionName, id), newActivity);
      console.log(`Attività aggiunta con successo alla collezione ${collectionName}`);
    } catch (e) {
      console.error('Errore durante l\'aggiunta dell\'attività:', e);
    }
  }
},




  },
  computed: {

    filteredColumnDefs() {
      return this.currentColumnDefs.filter(col => col.field !== 'action');
    },
    currentColumnDefs() {
      return this.selectedTab === 'workspace' ? this.columnDefsCommesse : this.columnDefsProgetti;
    }
  }
};
</script>

<style scoped>
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-balham.css";
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

h2 {
  color: #7A929E;
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 0px;
  user-select: none;
  margin-top: 2px;
  border-radius: 2px;
  border: 1px solid #7A929E;
  letter-spacing: 1px;
  padding: 7px;
}

.search-container {
  margin-bottom: 10px;
  flex-direction: row;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

input[type="text"] {
  padding: 8px;
  font-size: 16px;
  box-sizing: border-box;
  margin-right: 10px;
  width: 300px;
  user-select: none;
}

button {
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
}

.excel-button, .settingtable-button, .share-button, .add-button, .cancelsmall-button {
  background-color: rgba(0,0,0,0);
  color: #7A929E;
  border: none;
  padding: 8px;
}

.excel-button .fas, .settingtable-button .fas, .share-button .fas, .add-button .fas {
  font-size: 22px;
}

.cancelsmall-button .fas {
  font-size: 16px;
}

.grid-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-right: -10px;
  height: 83.2vh;
  width: 100%;
}

button.active {
  background-color: #7A929E;
  color: white;
}

.column-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 600px;
  min-width: 350px;
  overflow-y: auto;
}

.column-dialog-content {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
  text-transform: capitalize;
}

.draggable {
  cursor: move;
}

.spacer {
  height: 20px;
}

.close-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.close-button {
  font-size: 13px;
  font-weight: bold;
  color: #787878;
  background-color: transparent;
  border: none;
}

.column-dialog h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #787878;
  text-align: center;
  width: 100%;
  user-select: none;
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

input[type="checkbox"] + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 14px;
  color: #787878;
}

input[type="checkbox"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 11px;
  height: 11px;
  border: 2px solid #7A929E;
  border-radius: 3px;
  background-color: #fff;
  transition: background-color 0.3s, border-color 0.3s;
  font-size: 12px;
}

input[type="checkbox"]:checked + label:before {
  background-color: #7A929E;
  border-color: #7A929E;
}

input[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.tabs-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5px;
  user-select: none;
}

.tabs-container button {
  background-color: #f1f1f1;
  border: 1px solid #7A929E;
  border-radius: 4px;
  margin-right: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  color: #7A929E;
}

.tabs-container button.active {
  background-color: #7A929E;
  color: white;
}

.tabs-container button:hover {
  background-color: #ddd;
}

.add-item-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #7A929E;
  font-size: 16px;
}

.action-button {
  background-color: #7A929E;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.action-button:hover {
  background-color: #5A6F7E;


}

.loading-message {
  text-align: center;
  font-size: 16px;
  color: #7A929E;
  padding: 20px;
}

.workspace-name {
  font-size: 16px;
  color: #7A929E;
  margin-left: 10px;
  font-weight: bold;
  margin-top: 11px;
}


  .add-column-button,
  .add-row-button {
    background-color: #7A929E;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    margin-left: 5px;
  }

  .add-column-button:hover,
  .add-row-button:hover {
    background-color: #5A6F7E;
  }

</style>
