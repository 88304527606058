// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDXeyRUFVUGqhGz-HKg89znV9cvMkUUMAM",
    authDomain: "fluttere-distribuzione.firebaseapp.com",
    projectId: "fluttere-distribuzione",
    storageBucket: "fluttere-distribuzione.appspot.com",
    messagingSenderId: "195729499651",
    appId: "1:195729499651:web:cb2b353eed6bc888f85036"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Imposta la persistenza su locale
setPersistence(auth, browserLocalPersistence)
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

export { auth };