<template>
  <div class="login-background">
    <div class="login-container">
      <img src="@/assets/logo.svg" alt="Logo" class="logo" />
      <h2>{{ isLogin ? 'LOGIN' : 'REGISTRAZIONE' }}</h2>
      <form @submit.prevent="isLogin ? login() : register()" class="login-form" autocomplete="on">
        
        <!-- Raggruppamento dei campi di registrazione -->
        <div v-if="!isLogin">
          <div class="form-group">
            <input v-model="firstName" type="text" placeholder="Nome" required autocomplete="given-name" />
            <input v-model="lastName" type="text" placeholder="Cognome" required autocomplete="family-name" />
          </div>

          <div class="form-group">
            <input v-model="taxCode" type="text" placeholder="Codice Fiscale" required />
            <input v-model="birthDate" type="date" placeholder="Data di Nascita" required autocomplete="bday" />
          </div>

          <div class="form-group">
            <input v-model="address" type="text" placeholder="Indirizzo" required autocomplete="street-address" />
            <input v-model="city" type="text" placeholder="Città" required autocomplete="address-level2" />
          </div>

          <div class="form-group">
            <input v-model="zipCode" type="text" placeholder="CAP" required autocomplete="postal-code" />
            <vue3-select
              v-model="province"
              :options="provinces"
              placeholder="Seleziona la Provincia"
              class="vue3-select"
            />
          </div>

          <div class="form-group">
            <input v-model="email" type="email" placeholder="Email" required autocomplete="email" />
            <input v-model="phone" type="tel" placeholder="Telefono" required autocomplete="tel" />
          </div>

          <div class="form-group">
            <input v-model="password" type="password" placeholder="Password" required @input="validatePassword" />
            <input v-model="confirmPassword" type="password" placeholder="Conferma Password" required />
          </div>
        </div>

        <!-- Form di Login -->
        <div v-else>
          <div class="form-group">
            <input v-model="email" type="email" placeholder="Email" required autocomplete="email" />
          </div>

          <div class="form-group">
            <input v-model="password" type="password" placeholder="Password" required />
          </div>

          <div class="checkbox-container">
            <input v-model="rememberMe" type="checkbox" id="rememberMe" />
            <label for="rememberMe">Memorizza Login</label>
          </div>
        </div>

        <!-- Sezione "Accettazione" -->
        <div v-if="!isLogin" class="checkbox-container">
          <input v-model="acceptPrivacy" type="checkbox" id="acceptPrivacy" required />
          <label for="acceptPrivacy">Accetto l'informativa sulla privacy</label>
        </div>

        <div v-if="!isLogin" class="checkbox-container">
          <input v-model="acceptCookies" type="checkbox" id="acceptCookies" required />
          <label for="acceptCookies">Accetto l'utilizzo dei cookie</label>
        </div>

        <!-- Bottone di invio -->
        <button type="submit">{{ isLogin ? 'ACCEDI' : 'REGISTRATI' }}</button>
      </form>

      <!-- Messaggi di errore e toggle per la registrazione/login -->
      <p class="error-message">{{ error }}</p>
      <p @click="toggleForm" class="toggle-message">{{ isLogin ? 'Non hai un account? Registrati' : 'Hai già un account? Accedi' }}</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { useRouter } from 'vue-router';
import Vue3Select from 'vue3-select';
import 'vue3-select/dist/vue3-select.css';

export default {
  name: 'LoginPage',
  components: { Vue3Select },
  setup() {
    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const password = ref('');
    const confirmPassword = ref('');
    const phone = ref('');
    const taxCode = ref('');
    const birthDate = ref('');
    const address = ref('');
    const city = ref('');
    const zipCode = ref(''); 
    const province = ref(''); 
    const rememberMe = ref(false);
    const acceptPrivacy = ref(false);
    const acceptCookies = ref(false);
    const error = ref('');
    const isLogin = ref(true);
    const router = useRouter();

    const provinces = ref([
      "Agrigento", "Alessandria", "Ancona", "Aosta", "Arezzo", "Ascoli Piceno", 
      "Asti", "Avellino", "Bari", "Barletta-Andria-Trani", "Belluno", 
      "Benevento", "Bergamo", "Biella", "Bologna", "Bolzano", "Brescia", 
      "Brindisi", "Cagliari", "Caltanissetta", "Campobasso", "Caserta", 
      "Catania", "Catanzaro", "Chieti", "Como", "Cosenza", "Cremona", 
      "Crotone", "Cuneo", "Enna", "Fermo", "Ferrara", "Firenze", "Foggia", 
      "Forlì-Cesena", "Frosinone", "Genova", "Gorizia", "Grosseto", "Imperia", 
      "Isernia", "L’aquila", "La spezia", "Latina", "Lecce", "Lecco", 
      "Livorno", "Lodi", "Lucca", "Macerata", "Mantova", "Massa-Carrara", 
      "Matera", "Messina", "Milano", "Modena", "Monza e Brianza", "Napoli", 
      "Novara", "Nuoro", "Oristano", "Padova", "Palermo", "Parma", "Pavia", 
      "Perugia", "Pesaro e Urbino", "Pescara", "Piacenza", "Pisa", "Pistoia", 
      "Pordenone", "Potenza", "Prato", "Ragusa", "Ravenna", "Reggio Calabria", 
      "Reggio Emilia", "Rieti", "Rimini", "Roma", "Rovigo", "Salerno", 
      "Sassari", "Savona", "Siena", "Siracusa", "Sondrio", "Sud Sardegna", 
      "Taranto", "Teramo", "Terni", "Torino", "Trapani", "Trento", "Treviso", 
      "Trieste", "Udine", "Varese", "Venezia", "Verbano-Cusio-Ossola", 
      "Vercelli", "Verona", "Vibo Valentia", "Vicenza", "Viterbo"
    ]);

    const db = getFirestore();

    const generateTimestampId = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hour = String(now.getHours()).padStart(2, '0');
      const minute = String(now.getMinutes()).padStart(2, '0');
      const second = String(now.getSeconds()).padStart(2, '0');
      const millisecond = String(now.getMilliseconds()).padStart(3, '0');
      return `us-ID${year}${month}${day}${hour}${minute}${second}${millisecond}`;
    };

    const validatePassword = () => {
      if (isLogin.value) return true; // Skip validation in login mode

      const passwordValue = password.value;
      const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
      if (!passwordPattern.test(passwordValue)) {
        error.value = 'La password deve contenere almeno una lettera maiuscola, una minuscola, un numero e un carattere speciale, e deve essere lunga almeno 8 caratteri.';
        return false;
      }
      if (password.value !== confirmPassword.value) {
        error.value = 'Le password non coincidono.';
        return false;
      }
      error.value = ''; // Rimuove l'errore se la password è valida e coincide
      return true;
    };

    const clearError = () => {
      error.value = '';
    };

    // Watchers per password e confirmPassword
    watch([password, confirmPassword], () => {
      if (!isLogin.value && password.value !== confirmPassword.value) {
        error.value = 'Le password non coincidono.';
      } else {
        clearError();
      }
    });

    const login = async () => {
      const auth = getAuth();
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email.value, password.value);
        const user = userCredential.user;

        // Recupera l'ID utente dal database
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userId = userDoc.data().uid;
          this.setGlobalUserId(userId); // Salva l'ID come variabile globale
        } else {
          throw new Error("Utente non trovato nel database.");
        }
        if (rememberMe.value) {
          localStorage.setItem('email', email.value);
          localStorage.setItem('password', password.value);
          localStorage.setItem('rememberMe', rememberMe.value);
        } else {
          localStorage.removeItem('email');
          localStorage.removeItem('password');
          localStorage.removeItem('rememberMe');
        }
        router.push('/dashboard');
      } catch (err) {
        error.value = 'Login fallito: ' + err.message;
      }
    };

    const register = async () => {
      if (!validatePassword()) {
        return;
      }

      const auth = getAuth();
      try {
        // Genera l'ID personalizzato
        const customUserId = generateTimestampId();

        // Crea l'utente utilizzando email e password
        const userCredential = await createUserWithEmailAndPassword(auth, email.value, password.value);
        const firebaseUid = userCredential.user.uid; // Ottieni l'UID generato da Firebase

        // Salva i dati nella collezione 'users' utilizzando l'UID di Firebase come ID del documento
        await setDoc(doc(db, "users", firebaseUid), {
          id: customUserId,  // Salva il tuo ID personalizzato nel campo 'id'
          firebaseUid: firebaseUid,  // Salva l'UID di Firebase in un altro campo
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          phone: phone.value,
          taxCode: taxCode.value,
          birthDate: birthDate.value,
          address: address.value,
          city: city.value,
          province: province.value,
          zipCode: zipCode.value,
          acceptPrivacy: acceptPrivacy.value,
          acceptCookies: acceptCookies.value,
          sidebar: true,
          sidebarlock: false,
          theme: 'light',
          shared: []
        });

        this.setGlobalUserId(customUserId); // Salva l'ID personalizzato come variabile globale
        router.push('/dashboard');
      } catch (err) {
        error.value = 'Registrazione fallita: ' + err.message;
      }
    };



    const toggleForm = () => {
      isLogin.value = !isLogin.value;
      error.value = '';
    };

    onMounted(() => {
      if (isLogin.value) {
        const savedRememberMe = localStorage.getItem('rememberMe') === 'true';
        if (savedRememberMe) {
          email.value = localStorage.getItem('email') || '';
          password.value = localStorage.getItem('password') || '';
          rememberMe.value = true;
        }
      }
    });

    return {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      phone,
      taxCode,
      birthDate,
      address,
      city,
      zipCode,
      province,
      provinces,
      rememberMe,
      acceptPrivacy,
      acceptCookies,
      error,
      isLogin,
      login,
      register,
      toggleForm,
      validatePassword,
      clearError,
      generateTimestampId,
    };
  },
};
</script>


<style scoped>
.login-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #f0f0f0, #d9d9d9);
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  padding: 30px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  width: 250px;
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 20px;
  font-size: 21px;
  letter-spacing: 3px;
  color: #7B939F;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.login-form {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.form-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 12px;
}

.form-group input {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #E2E8F0;
  border-radius: 6px;
  color: #2D3748;
}

.checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.checkbox-container input {
  margin-right: 10px;
}

.checkbox-container label {
  font-size: 14px;
  color: #7B939F;
}

.login-form button {
  padding: 12px;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
  background-color: #7B939F;
  border-radius: 1px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: #405D6B;
}

.error-message {
  margin-top: 20px;
  color: red;
  font-size: 14px;
}

.toggle-message {
  margin-top: 20px;
  color: #2B6CB0;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

.vue3-select {
  color: #2D3748;
  width: 100%;
  max-width: 100%; 
  background-color: #fff;
}
</style>
